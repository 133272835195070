import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import axios from 'axios'

import { SectionTitle } from "../Section";
import PrimaryButton from "../Button/PrimaryButton";
import LineArrowRight from "../Icons/LineArrowRight"

const FormWrap = styled.div`
	position: relative;
	padding: 57px 52px;
	background: ${props => props.bgColor};
	border-radius: 6px;

	form {
		width: 100%;

		p {
			margin-bottom: 30px;
		}

		.two-col {
			display: flex;
			align-items: center;
			justify-content: space-between;

			input {
				width: 48%;
				padding: 10px 20px 12px;
				border: 1px solid #D0D0D0;
				border-radius: 6px;
				margin-bottom: 15px;
				box-shadow: 0 3px 12px rgba(0, 0, 0, .1);
			}
		}
		textarea {
			width: 100%;
			padding: 10px 20px 12px;
			border: 1px solid #D0D0D0;
			border-radius: 6px;
			margin-bottom: 15px;
			box-shadow: 0 3px 12px rgba(0, 0, 0, .1);
			resize: none;
		}
	}
	@media(max-width: 480px) {
		form {
			.two-col {
				display: block;

				input {
					width: 100%;
					padding: 10px;
				}

				textarea {
					padding: 10px;
				}
			}
		}
	}
	@media(max-width: 400px) {
		padding: 25px 10px;

		form {
			p {
				margin-bottom: 10px;
			}
			.two-col {
				input {
					margin-bottom: 10px;
				}
			}
			textarea {
				margin-bottom: 0;
			}
		}
	}
`

class QuoteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      full_name: '',
      email: '',
      phone: '',
      zipcode: '',
      comment: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
		const {id, value} = event.target
		this.setState({
			[id]: value
		})
	}
  handleSubmit(event) {
    event.preventDefault();
		const { location } = this.props;
    const data = {
			source: location.origin,
			form: "Quote Form",
			page: location.pathname,
      first_name: this.state.full_name,
      email: this.state.email,
      phone_no: this.state.phone,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
    }
    axios({
      method: "post",
       url: "https://crm.senseicrm.com/api/create-lead-wordpress",
       data: data,
       headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
     }).then((res) => {
       this.setState({ full_name: '', email: '', phone: '', zipcode: '', comment: ''});
       navigate("/thank-you-get-a-quote");
     }).catch((error) => {
         alert(error)
     });
  }
  render() {
	const { background } = this.props;
    return(
    	<FormWrap bgColor={background ? background: "#F7F5F5"}>
				<form id="QuoteForm" onSubmit={this.handleSubmit}>
					<SectionTitle textAlign="left">Get A Quote</SectionTitle>
					<p>We not only fulfill your metal building needs, but we also make dreams come true.</p>
					<div className="two-col">
						<input type="text" name="full_name" id="full_name" onChange={this.handleChange} placeholder="Full Name" pattern="[A-Za-z\s]+$" required />
						<input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email Id" required />
					</div>
					<div className="two-col">
						<input type="tel" name="phone" id="phone" onChange={this.handleChange} placeholder="Contact Number" pattern="[0-9]{10}" required />
						<input type="text" name="zipcode" id="zipcode" onChange={this.handleChange} pattern="[0-9]+$"  placeholder="Zip Code" required />
					</div>
					<textarea name="comment" placeholder="comment" onChange={this.handleChange}  id="comment" rows="4" />
					<button type="submit">
						<PrimaryButton text="Submit" icon={<LineArrowRight />} />
					</button>
				</form>
			</FormWrap>
    );
  }
}

export default QuoteForm;