import React,  { useState, useContext, useEffect } from "react";
import { Link, navigate } from 'gatsby';
import styled from "styled-components";
import DropArrow from "../Icons/DropArrow";
import SteelBuildingsDropdown from "./DropDown/SteelBuildings";
import PortableBuildingsDropdown from "./DropDown/PortableBuildings";
import ResourcesDropdown from "./DropDown/Resources";
import PrimaryLinkButton from "../Button/PrimaryLinkButton";
import SecondButton from "../Button/SecondButton";
import LineArrowRight from "../Icons/LineArrowRight"
import PhoneIcon from "../Icons/PhoneIcon"
import ShedIcon from "../Icons/ShedIcon"
import ThreeDIcon from "../Icons/ThreeDIcon"
import MenuCloseIcon from "../Icons/MenuCloseIcon"
import Logo from "../../images/bam-logo.svg";
import SearchIcon from "../Icons/SearchIcon"
import SearchCloseIcon from "../Icons/SearchCloseIcon"
// import { StaticImage } from 'gatsby-plugin-image';

const MenuWrap = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	.logo {
		> a{
			display: block;
    		width: 100%;
		}
		img {
			width: 120px;
			vertical-align: middle;
		}
	}
	
	@media(min-width: 1200px) {
		display: none;
	}

	.right-wrap {
		display: flex;
		align-items: center;
		.search-icon {
			margin-right: 15px;
		}
		.btn {
			margin-top: -2px;
		}
	}
`

const Hamburgerbutton = styled.div`
	position: relative;
	z-index: 11;
	display: block;
	margin-left: 20px;
	width: 30px;
	height: 30px;
	padding-top: ${props => (props.visible ? "5px": "2px")};

	span {
		display: block;
		width: 30px;
		height: 3px;
		background: #000000;
		transform: ${props => (props.visible ? "rotate(45deg)": "rotate(0)")};
	}

	span:nth-child(2) {
		width: 20px;
		margin-top: 10px;
		margin-left: auto;
		display: ${props => (props.visible ? "none": "block")};
	}

	span:nth-child(3) {
		margin-top: ${props => (props.visible ? "-3px": "10px")};
		transform: ${props => (props.visible ? "rotate(-45deg)": "rotate(0)")};
	}
	
	@media(min-width: 1200px) {
		display: none;
	}
`

const CloseButton = styled.div`
	position: absolute;
	top: 25px;
	right: 25px;
	z-index: 11;
	width: 40px;
	height: 40px;
	display: flex;
    align-items: center;
    justify-content: center;
	> svg{
		width: 15px;
		height: 15px;
	}
`

const MobileDropdown = styled.div`
	position: fixed;
	z-index: 201;
	top:0;
	right:0;
	width:100%;
	height:100vh;
	max-width: 500px;
	display: ${props => (props.visible ? "block": "none")};
	padding: 70px 0 0;
	overflow-y: auto;
	background-color:#fff;
	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;
		}
	}
	.menus {
		/* height: calc(100vh - 305px);
		overflow-y: scroll; */
	}
	
	@media(min-width: 1200px) {
		display: none;
	}
`

const MenuItem = styled.div`
	position: relative;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top:20px;
	padding: 0 40px;
	color: #574C4C;
	flex-wrap: wrap;
	svg {
		stroke: #574C4C;
	}
`
const MenuAlone = styled(Link)`
	position: relative;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top:20px;
	padding: 0 40px;
	color: #574C4C;
	flex-wrap: wrap;
`
const ExploreButton = styled.div`
	padding:20px 15px 80px 15px;
`
const ExploreItem = styled.div`
	&.explore-item{
		+ .explore-item{
			margin-top:15px;
		}
	}
	.btn{
		width:100%;
	}
	a{
		display:block;
	}
`

const SearchForm = styled.div`
	position: absolute;
	display: none;
	width: 100%;
	max-width: 400px;
	top: 64px;
	right: 0;
	z-index: 10;
	&.show {
		display: block;
	}
`

const SearchContainer = styled.div`
  position: relative;
  max-width: 300px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 20px;
  @media (min-width: 992px){
    padding: 0px 30px;
  }

	input {
		padding: 10px 15px;
	}
  .input-icon{
    > .form-control{

      &::placeholder {
        color:#004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color:#004485;
        }
      }
    }
    .icon {
      cursor:pointer;
      width:45px;
      > svg{
        fill:#4815D6;
        width:15px;
        height:15px;
      }
      &:hover{
        > svg{
          fill:#4F5864;
        }
      }
    }
    &.right {
      > .form-control{
        padding-right: 45px;
        padding-left: 15px;
      }
    }
  }
`

const MobileNav = ({ hideSearch }) => {
	const [visible, setVisible] = useState(false);

	const [currentMenu, setCurrentMenu] = useState("");
	const [searchKey, setSearchKey] = useState(null)
	const [showSearch, setShowSearch] = useState(true) 

  const handleChange = (e) => {
    setSearchKey(e.target.value);
  }

	const ToggleSearch = () => {
    setShowSearch(!showSearch);
  }

	const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/search-result", { state: { searchKey: searchKey } });
  }

	const handleMenu = (menu) => {
		if (currentMenu === menu ) {
			setCurrentMenu("")
		} else {
			setCurrentMenu(menu)
		}
	}
	useEffect(() => {
		document.body.classList.toggle('menu-open', visible)
	}, [visible, setVisible])
	return(
		<MenuWrap>
			<Link to="/" className="logo">
				<img src={Logo} alt="mobile-logo" />
			</Link>
			<div className="right-wrap">
				{!hideSearch && <div className="search-icon" aria-label="Search" onClick={ToggleSearch}><PrimaryLinkButton icon={<SearchIcon />} /></div>}
				<div>
					<a href="tel:3867556449" aria-label="tel"><SecondButton text="" icon={<PhoneIcon fill="#fff" />} /></a>
				</div>
				<Hamburgerbutton onClick={() => setVisible(!visible)}>
					<span></span>
					<span></span>
					<span></span>
				</Hamburgerbutton>
				<SearchForm className={showSearch ? 'search-form' : 'search-form show'}>
					<SearchContainer className='search-container'>
						<form onSubmit={(e)=>handleSubmit(e)}>
							<div className="input-icon right">
								<input  className="form-control" type="text" placeholder="Search..." id="search" name="search" onChange={(e) => handleChange(e)} />
								<button  type="submit" aria-label="Close Search" className="icon"><SearchIcon /></button>
							</div>
						</form>
					</SearchContainer>
				</SearchForm>
			</div>
			<MobileDropdown visible={visible}>
				<CloseButton visible={visible} onClick={()=>setVisible(false)}><MenuCloseIcon /></CloseButton>				
				<div className="menus">
					<div>
						<MenuItem onClick={() => handleMenu("Steel Buildings")}>
							Steel Buildings <DropArrow />
						</MenuItem>
						<SteelBuildingsDropdown display={currentMenu==="Steel Buildings"? "block" : "none"} />
					</div>
					<div>
						<MenuItem onClick={() => handleMenu("Portable Buildings")}>
							Portable Buildings <DropArrow />
						</MenuItem>
						<PortableBuildingsDropdown display={currentMenu==="Portable Buildings"? "block" : "none"} />
					</div>
					<div>
						<MenuAlone  to="/red-iron-buildings">
							Red Iron Buildings 
						</MenuAlone>
					</div>
					<div>
						<MenuAlone  to="/pole-barns">
							Pole Barns 
						</MenuAlone>
					</div>
					<div>
						<MenuAlone  to="/gazebos">
							Gazebos
						</MenuAlone>
					</div>
					<div>
						<MenuAlone  to="/greenhouses">
							Greenhouses
						</MenuAlone>
					</div>
					<div>
						<MenuAlone  to="/screen-rooms">
							Screen rooms
						</MenuAlone>
					</div>
					<div>
						<MenuItem onClick={() => handleMenu("Resources")}>
							Resources <DropArrow />
						</MenuItem>
						<ResourcesDropdown display={currentMenu==="Resources"? "block" : "none"} />
					</div>
				</div>
				<ExploreButton>
					<ExploreItem className="explore-item">
						<a href="tel:3867556449" aria-label="tel"><SecondButton text="(386) 755-6449" icon={<PhoneIcon fill="#fff" />} /></a>
					</ExploreItem>
					<ExploreItem className="explore-item">
						<a href="https://bambuildingsheds.senseisheds.com/" target="_blank" aria-label="3D Shed Designer" rel="noreferrer"><SecondButton text="3D Shed Designer" preIcon={<ShedIcon fill="#fff" />} icon={<LineArrowRight />} /></a>
					</ExploreItem>
					<ExploreItem className="explore-item"><a href="https://buildingsandmore.sensei3d.com/" target="_blank" aria-label="3D Carport Designer" rel="noreferrer"><PrimaryLinkButton text="3D Carport Designer" preIcon={<ThreeDIcon fill="#fff" />} icon={<LineArrowRight />} /></a></ExploreItem>
				</ExploreButton>
			</MobileDropdown>
		</MenuWrap>
	)
}

export default MobileNav;