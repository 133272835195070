import React, {useContext} from "react";
import styled from "styled-components";

import QuoteForm from "./QuoteForm";

import { Context } from "../../layouts/context";

const Wrapper = styled.div`
	position: fixed;
	z-index: 300;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background:	rgba(0, 0, 0, .8);
	display: ${props => props.display};
`
const Container = styled.div`
	position: absolute;
	width: 700px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media(max-width: 768px) {
		width: 90%;
	}
`


const CloseButton = styled.div`
	position: absolute;
	top: -15px;
	right: -20px;
	width: 15px;
	height: 15px;

	:hover {
		cursor: pointer;
	}

	span {
		display: block;
		width: 15px;
		height: 2px;
		border-top: 2px solid #ffffff;
	}

	span:first-child {
		transform: rotate(45deg);
	}
	span:last-child {
		margin-top: -2px;
		transform: rotate(-45deg);
	}
	@media(max-width: 480px) {
		right: -13px;
	}
`
const QuotePopUp = ({ location }) => {
	
	const [popupContext, setPopupContext] = useContext(Context);

	return(
		<Wrapper display={popupContext}>
			<Container>
				<CloseButton onClick={() => setPopupContext("none")}>
					<span></span>
					<span></span>
				</CloseButton>
				<QuoteForm location={location} />
			</Container>
		</Wrapper>
	)
}

export default QuotePopUp;