import styled, { keyframes } from "styled-components";
import BreakpointUp from "../Media/BreakpointUp"

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const RingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const DualRing = styled.div`
  display: block;
  width: 80px;
  height: 80px;
	margin: 100px auto;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000000;
    border-color: #000000 transparent #000000 transparent;
    animation: ${RingAnimation} 1.2s linear infinite;
  }
`

export const Fade = styled.div`
	animation-duration: 1s;
	animation-name: ${FadeIn};
	animation-fill-mode: both;
`;

export const Section = styled.section`
	position: relative;	
	background-image: ${props=>props.bgImg};
	background-color: ${props=>props.bgColor};	
	background-size:${props => props.bgSize};
	text-align: ${props=>props.textAlign};
	overflow: ${props=>props.overflow};
	border-bottom:${props=>props.bdrWidth} solid ${props=>props.bdrColor};
	box-shadow: ${props=>props.boxShadow};
	margin-top: ${props=>props.mt};
	margin-bottom: ${props=>props.mb};
	z-index: ${props=>props.zIndex};

	.container {
		position: relative;
		z-index: 2;
	}
	&:before{
		content:'';
		display:${props => (props.bgBefore ? 'block' : 'none')};
		position:absolute;
		top:${props => props.topBefore};
		right:${props => props.rightBefore};
		bottom:${props => props.bottomBefore};
		right:${props => props.leftBefore};
		background:${props => props.bgBefore};
		background-size:${props => props.bgBeforeSize};
		background-repeat: ${props => props.bgBeforeRepeat};
		width:${props => props.bgBeforeWidth};
		height:${props => props.bgBeforeHeight};
		opacity:${props => props.opacityBefore};
	}
	&:after{
		content:'';
		display:${props => (props.bgAfter ? 'block' : 'none')};
		position:absolute;
		top:${props => props.topAfter};
		right:${props => props.rightAfter};
		bottom:${props => props.bottomAfter};
		right:${props => props.leftAfter};
		background:${props => props.bgAfter};
		background-size:${props => props.bgAfterSize};
		background-repeat: ${props => props.bgAfterRepeat};
		width:${props => props.bgAfterWidth};
		height:${props => props.bgAfterHeight};
		opacity:${props => props.opacityAfter};
	}
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};

	@media (max-width: 991px){
		padding-top: ${props=>props.mpt};
		padding-bottom: ${props=>props.mpb};
		margin-top: ${props=>props.mmt};
		margin-bottom: ${props=>props.mmb};
	}
	@media (max-width: 480px){
		padding-top: ${props=>props.spt};
		padding-bottom: ${props=>props.spb};
		margin-top: ${props=>props.smt};
		margin-bottom: ${props=>props.smb};
		.container {
			width: 100%;
		}
	}
`

Section.defaultProps = {
	bgImg: "inherit",
	bgColor: "transparent",
	bgSize: "inherit",
	textAlign: "left",
	bdrColor:"transparent",
	bdrWidth: "0",
	boxShadow:"none",
	pt: "100px",
	pb: "100px",
	mt: '0',
	mb: '0',
	mpt: '80px',
	mpb: '80px',
	spt: '40px',
	spb: '40px',
}

export const SectionBgWrap = styled.div`
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: ${props => props.opacity};
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}

	@media(max-width: 991px) {
		opacity: ${props => props.mOpacity};
	}
`

SectionBgWrap.defaultProps = {
	opacity: '1',
	mOpacity: '1',
}

export const SectionPageTitle = styled.h1`
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	margin-right:${props=>props.mr};
	width:100%;
	position: relative;
	font-size: 38px;
	line-height:${props=>props.lineHeight};

	@media(max-width: 600px) {
		font-size: 28px;
		line-height: 42px;
	}
`

SectionPageTitle.defaultProps = {
	textAlign: "center",
	color: "#000",
	maxWidth: "100%",
	mt: "auto",
	mr: "auto",
	ml: "auto",
	mb: "15px",
	lineHeight: "56px"
}

export const SectionTitle = styled.h2`
    font-size: 38px;
    line-height: 56px;
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	margin-right:${props=>props.mr};
	padding-left:${props=>props.pl};
	padding-right:${props=>props.pr};
	width:100%;
	position: relative;
	@media(max-width: 991px) {
		margin-top: ${props=>props.mpt};	
		margin-bottom: ${props=>props.mpb};	
	}
	@media(max-width: 600px) {
		font-size: 24px;
		line-height: 32px;
		margin-top: ${props=>props.spt};	
		margin-bottom: ${props=>props.spb};	
	}
`

SectionTitle.defaultProps = {
	textAlign: "center",
	color: "#000",
	maxWidth: "100%",
	mt: "auto",
	mr: "auto",
	ml: "auto",
	mb: "15px",
	pl: "0px",
	pr: "0px"
}

export const SubTitle = styled.h3`
	margin-top: ${props=>props.mt};
	margin-left:${props=>props.ml};
	margin-right:${props=>props.mr};
	margin-bottom: ${props=>props.mb};	
	text-align: ${props=>props.textAlign};
	max-width:${props=>props.maxWidth};
	color: ${props=>props.color};
	width: ${props=>props.color};
	font-size: ${props=>props.fontSize};
	line-height: 51px;
	font-weight: 700;
	@media(max-width: 991px) {
		margin-top: ${props=>props.mpt};	
		margin-bottom: ${props=>props.mpb};	
	}
	@media(max-width: 600px) {
		font-size: 24px;
		line-height: 32px;
		margin-top: ${props=>props.spt};	
		margin-bottom: ${props=>props.spb};	
	}
`
SubTitle.defaultProps = {
	fontSize: "30px",
	textAlign: "center",
	maxWidth: "100%",
	color: "#000",
	ml: "auto",
	mr: "auto",
	mb: "30px",
	mt: "30px",
	width: "100%",
}


export const SectionLead = styled.p`
	margin-left:${props=>props.ml};
	margin-right:${props=>props.mr};
	margin-bottom: ${props=>props.mb};	
	text-align: ${props=>props.textAlign};
	max-width:${props=>props.maxWidth};
	color: ${props=>props.color};
	width:100%;
	font-size: ${props=>props.fontSize};
	line-height:32px;
	font-weight: 500;
	@media (max-width: 1600px) {
		font-size: 16px;
		line-height:26px;
	}
`
SectionLead.defaultProps = {
	fontSize: "18px",
	textAlign: "center",
	maxWidth: "100%",
	color: "#666",
	ml: "auto",
	mr: "auto",
	mb: "30px",
}

export const SectionDescription = styled.div`
	margin-left: ${props=>props.ml};
	margin-right:${props=>props.mr};
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width:${props=>props.maxWidth};
	color: ${props=>props.color};
	padding-left:${props=>props.pl};
	padding-right:${props=>props.pr};
	font-family: ${props=>props.fontFamily};
	width:100%;
	position: relative;

	& p {
		margin-bottom:0;
		font-size: 16px;
		line-height:24px;
		@media (min-width: 1600px) {
			font-size: 18px;
			line-height:32px;
		}
		
		+ p{
			margin-top:20px;
		}
		a {
			color:#EC1C24;
			&:hover, &:focus {
				text-decoration: underline;
			}
		}
	}
	
`
SectionDescription.defaultProps = {
	textAlign: "center",
	mb: "30px",
	maxWidth: "100%",
	color: "#666",
	ml: "auto",
	mr:"auto",
	pl: "0px",
	pr: "0px",
	fontFamily: 'inherit'
}

export const Container = styled.div`
	position: relative;  
    width: 100%;
    margin-right: auto;
	margin-left: auto;
    padding-right: ${props=>props.pr};
    padding-left: ${props=>props.pl};
	max-width: ${props=>props.maxWidth};
	overflow: ${props=>props.overflow};
`
Container.defaultProps = {
	maxWidth: "1380px",
	overflow: "inherit",
	pr: "15px",
	pl: "15px",
}

export const BreadCrumb = styled.div`		
	position: ${props=>props.position};	
	z-index:2;
	padding: 7px 0 8px;
	background: #ffffff;
	font-weight: normal;

	.container {
		magin: 0;
		line-height: 1.3;
	}

	a {
		color: #E43D3C;
		line-height: 1;
	}
`

export const IsSticky = styled.div`
	top: 75px;
	${BreakpointUp.xl`
		position: sticky;
		top: 135px;
	`}
`

export const Badge = styled.span`	
	background-color:${props=>props.bgColor};
    border-radius:${props=>props.radius};
	color:${props=>props.color};
	font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 2px 7px;
`

Badge.defaultProps = {
	bgColor: "#EDEDED",
	color: "#707070",
	radius: "20px"
}

export const MarkdownContent = styled.div`
	display: ${props=>props.display};
	max-width:${props=>props.maxWidth};
	padding-left:${props=>props.pl};
	padding-right:${props => props.pr};
	margin:0 auto;
	margin-top:${props => props.mt};
	margin-bottom:${props=>props.mb};
	text-align:${props=>props.textAlign};
	width:100%;
	z-index: 1;
	position: relative;	

	h1 {
		font-size: 60px;
		margin-bottom: 50px;
	}
	h2{
		&:first-child{
			padding-top:0;
		}
		padding-top: 10px;
		@media (min-width: 1200px) {
			padding-top: 20px;
		}
		@media (min-width: 1600px) {
			padding-top: 40px;
		}
		
	}

	h3{
		padding-top: 5px;
		@media (min-width: 1600px) {
			padding-top: 10px;
		}
	}
	& ul{
		margin-bottom: 30px;
		@media (min-width: 1600px) {
			margin-bottom: 40px;
		}
	}
	& hr{
		margin:30px 0;
		@media (min-width: 1200px) {
			margin:40px 0;
		}
		@media (min-width: 1600px) {
			margin:60px 0;
		}
		+ h2, + h3{
			padding-top:0;
		}
	}

	& p {
		color: ${props=>props.color};

		a {
			color: #EC1C24;
		}
	}

	& span {
		line-height:28px;
	}
	& strong {
		color:#000;
	}

	& a {
		color: #EC1C24;
		font-weight:700;
		&:hover, &:focus{
			text-decoration: underline;
		}
	}
	
	& ul {
		padding:0;
		font-weight: 500;
        color:#000;
		font-size:16px;
		line-height:26px;
		margin-left: 20px;
		list-style: circle;
		@media (min-width: 1600px) {
			font-size:18px;
			line-height:32px;
		}
		& li {
			padding-left: 0px;
			vertical-align: text-top;
			margin: 0;
			color: ${props=>props.color};
			
			+ li{
				margin-top:5px;
				color: inherit;
			}

			&::marker {
				color: red;
			}
		}
	}
	& table {
		width: 100%;
		min-width: 100%;
		thead {
			background: #938888;

			th {
				border: 0px;
				color: #FFFFFF;
				font-size: 16px;
			}
			th:first-child {
				padding-left: 30px;
			}
		}
		tbody {
			tr {
				background: #F7F5F5;
				border-bottom: 3px solid #ffffff;

				td {
					font-size: 14px;
				}

				td:first-child {
					padding-left: 30px;
				}
			}
		}
		@media (max-width: 991px){
			min-width: 900px;
		}
	}
`

MarkdownContent.defaultProps = {
	textAlign: "left",
	maxWidth: "none",
	pl: "0",
	pr: "0",
	display: 'block',
	mt: "0",
	mb:"0"
}

export const MarkdownBody = styled.div`
	position: relative;
    box-shadow:0 25px 50px rgba(3,51,102,0.2);
	background-color:${props=>props.bgColor};
	max-width:${props=>props.maxWidth};
	padding: 40px 15px;    
    @media (min-width: 576px){
		padding:50px 30px;    
	}
    @media (min-width: 1200px){
        padding: 60px 30px;
    }
    @media (min-width: 1600px){
        padding: 90px 30px;
    }
`
MarkdownBody.defaultProps = {
	maxWidth: "none",
	bgColor:"#fff"
}

export const MarkDownRow = styled.div`    
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    margin:0 -15px;
    @media (min-width: 992px){ 
        margin:0 -20px;
    }
`
export const MarkDownTwocol = styled.div`
    position: relative;
    width:100%;
    padding:0 15px;
	margin-bottom:30px;
    @media (min-width: 992px){ 
        padding:0 20px;
        flex: 0 0 50%;
        max-width: 50%;
		margin-bottom:0;
    }
	p{
		margin-bottom:0;
		+ p, + ul{
			margin-top:30px;
			@media (min-width: 1600px) {
				margin-top:40px;
			}
		}
	}
`
export const MarkDownLeft = styled.div`
    position: relative;
    width:100%;
    padding:0 15px;
	margin-bottom:30px;
    @media (min-width: 992px){ 
        padding:0 20px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
		margin-bottom:0;
    }
	p{
		margin-bottom:0;
		+ p, + ul{
			margin-top:30px;
			@media (min-width: 1600px) {
				margin-top:40px;
			}
		}
	}
`
export const MarkDownRight = styled.div`
    position: relative;
    width:100%;
    padding:0 15px;
    .gatsby-image-wrapper {
    	width: 100%;
    }
    @media (min-width: 992px){ 
        padding:0 20px;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    @media (min-width: 1600px){ 
        flex: 0 0 25%;
        max-width: 25%;
    }
`
export const BaseButton = styled.span`
	display: inline-flex;
    align-items: center;
	position:relative;
	text-align:center;
	border-radius: 4px;
	padding:9px 15px;
	font-size:16px;
	font-weight:700;
	border:1px solid transparent;
	text-transform:uppercase;
	letter-spacing: 1px;
	justify-content: center;
	box-sizing: border-box;
    overflow: hidden;
	transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
	&:before, &:after {
		content: "";
		position: absolute;
		display: block;
		box-sizing: border-box;
		top: 0;
		left: 0;
	}
	&:after {
		top: 0;
		left: 0;
		width: 0;
		height: 100%;	
		mix-blend-mode: screen;
		transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
	}
	&:hover {
		&:after {
			width: 100%;
			height: 100%;
		}
	}
	> .text{
		line-height: 24px;		
		+ .icon{
			margin-left:10px;
		}
	}
	> .icon{
		height: 24px;
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
		+ .text{
			margin-left:10px;
		}
		&:empty {
			display: none;
		}
	}
`
export const StepWrapper = styled.div`
	position: absolute;
	width: 50px;
	color: ${props=>props.color};
	top: ${props=>props.top};
	left: ${props=>props.left};
	text-align: center;
	@media(max-width: 1600px) {
		left: 10px;
	}
	& span {
		display:block;
		font-size:11px;
		line-height:20px;
		font-weight:500;
	}
	& strong {
		display:block;
		font-family: "Barlow", sans-serif;
		font-weight: 700;
		color: ${props=>props.color};
		font-size: 20px;
		line-height: 24px;
		@media (min-width: 768px) {
			font-size: 22px;
			line-height: 24px;
		}
		@media (min-width: 1600px) {
			font-size: 24px;
			line-height: 24px;
		}
	}
	& svg {
		margin-top: 10px;
	}
	@media(max-width: 1200px) {
		display: none;
	}
`
StepWrapper.defaultProps = {
	top: "200px",
	color: "#FFFFFF33",
	left: "calc(50vw - 780px)"
}

export const HalfStep = styled.div`
  position: absolute;
  left: 23px;
`

export const SectionInnerWhite = styled.div`  
	background-color:${props=>props.bgColor};
	position: relative;
	box-shadow: 0 25px 50px rgb(3 51 102 / 20%);
	padding-top:40px;
	padding-bottom:40px;
	padding-left:${props=>props.pl};
	padding-right:${props=>props.pr};
	${BreakpointUp.xl`    
		padding-top:60px;
		padding-bottom:60px;
		padding-left:${props=>props.mpl};
		padding-right:${props=>props.mpr};
	`}
	${BreakpointUp.xxl`    
		padding-top:90px;
		padding-bottom:90px;
		padding-left:${props=>props.xpl};
		padding-right:${props=>props.xpr};
	`}
`
SectionInnerWhite.defaultProps = {
	bgColor:"#fff",

	pl:"4.166%",
	pr:"4.166%",
	
	mpl:"4.166%",
	mpr:"4.166%",

	xpl:"8.333%",
	xpr:"8.333%",
}

export const FlexWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: ${props=>props.justifyContent};
	align-items: ${props=>props.alignItems};
	max-width: ${props=>props.maxWidth};
	margin-left: ${props=>props.ml};
	margin-right: ${props=>props.mr};
	margin-top: ${props=>props.mt};
	margin-bottom: ${props=>props.mb};
	@media(max-width: 991px) {
		flex-direction: ${props => props.flexDirection};
	}
`

FlexWrap.defaultProps = {
	alignItems: "flex-start",
	maxWidth: "100%",
	justifyContent: "space-between",
	ml: "auto",
	mr: "auto",
	mt: '0',
	mb: '0',
	flexDirection: 'inherit',
}

export const RightWrap = styled.div`
	width: ${props => props.width};
	margin-left: ${props => props.ml};
	margin-right: ${props => props.mr};
	margin-top: ${props => props.mt};
	margin-bottom: ${props => props.mb};
	@media(max-width: 991px) {
		width: 100%;
		margin-left: ${props => props.mml};
		margin-right: ${props => props.mmr};
		margin-top: ${props => props.mmt};
		margin-bottom: ${props => props.mmb};
	}
`

RightWrap.defaultProps = {
	width: "48%",
}

export const LeftWrap = styled.div`
	width: ${props => props.width};
	margin-left: ${props => props.ml};
	margin-right: ${props => props.mr};
	margin-top: ${props => props.mt};
	margin-bottom: ${props => props.mb};
	@media(max-width: 991px) {
		width: 100%;
		margin-left: ${props => props.mml};
		margin-right: ${props => props.mmr};
		margin-top: ${props => props.mmt};
		margin-bottom: ${props => props.mmb};
	}
`

LeftWrap.defaultProps = {
	width: "48%",
}

export const TextCenter = styled.div`
	text-align: center;
`

export const isBrowser = typeof window !== `undefined`;



export const ExploreMore = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:${props=>props.mt};
	margin-bottom:${props=>props.mb};
	justify-content:${props=>props.justifyContent};
	flex-direction:${props=>props.flexDirection};	
	.btn{
		text-transform:${props=>props.textTransform};	
	}
`
ExploreMore.defaultProps = {
	mt: "0px",
	mb: "0px",
	justifyContent: "center",
	flexDirection: "row",
	textTransform: "uppercase",
}