import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../components/Header";
import Footer from "../components/Footer";
import GetInTouch from "../components/GetInTouch";
import QuotePopUp from "../components/QuotePopUp";
import DropArrow from "../components/Icons/DropArrow";

import { Context } from "./context.js";

import "../scss/app.scss";

const ScrollTopBtn = styled.button`
  position: fixed;
  z-index: 200;
  top: calc(100vh - 100px);
  right: 50px;
  display: ${props => props.display};
  background: red;
  width: 50px;
  height: 50px;
  border-radius: 8px;

  svg {
    transform: rotate(180deg);
  }
  @media(max-width: 767px){
    right: 20px;
  }
`

const isBrowser = typeof window !== `undefined`;

const Layout = ({ children, hideSearch = false,  isHeader, footerCta, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [popupContext, setPopupContext] = useState("none");
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = isBrowser ? window.pageYOffset : 0;
    setScrollPosition(position);
  }

  const topFunction = () => {
    if(isBrowser) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition, setScrollPosition]);

  return (
    <Context.Provider value={[popupContext, setPopupContext]}>
      <Header isHeader={isHeader} siteTitle={data.site.siteMetadata?.title || `Title`} hideSearch={hideSearch} />
      <main>
        {children}
        <ScrollTopBtn display={scrollPosition > 400 ? "block" : "none"} onClick={() => topFunction()}>
          <DropArrow fill="#fff" />
        </ScrollTopBtn>
      </main>
      {footerCta && (<GetInTouch location={location} />)}
      <Footer />
      <QuotePopUp location={location} />
    </Context.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout