import React, { useState, useRef, useContext } from "react";
import styled from "styled-components";
import { StaticImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import PrimaryLinkButton from "../Button/PrimaryLinkButton";
import SecondButton from "../Button/SecondButton";
import LineArrowRight from "../Icons/LineArrowRight"
import PhoneIcon from "../Icons/PhoneIcon"
import NoteIcon from "../Icons/NoteIcon"
import DropArrow from "../Icons/DropArrow"
import ThreeDIcon from "../Icons/ThreeDIcon"
import ShedIcon from "../Icons/ShedIcon"
import SearchIcon from "../Icons/SearchIcon"
import SearchCloseIcon from "../Icons/SearchCloseIcon"

import PortableBuildingsDropdown from "./DropDown/PortableBuildings";
import SteelBuildingsDropdown from "./DropDown/SteelBuildings";
import ResourcesDropdown from "./DropDown/Resources";
//import Logo from "../../images/bam-logo.svg";

import MobileNav from "./MobileNav";
import useClickOutside from "../../utils/useClickOutside";

import { Context } from "../../layouts/context";

const HeaderWrap = styled.div`
	position: relative;
	z-index: 201;
	height:72px;
	@media (min-width: 1200px) {
		height:164px;
		padding: 10px 0;
	}
`
const HeaderInner = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right:0;
	height:72px;
	@media (min-width: 1200px) {
		height:164px;
	}
	
`
const Bg = styled.div`
	position: absolute;
	top: 0;
	z-index: 0;
	width: 100%;
	height: 100%;

	.gatsby-image-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		top: 0;
	}
`
const HeaderTop = styled.div`
	padding: 7px 12px;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #D0D0D0;	
	display: none;
	@media(min-width: 1200px) {
		display: flex;
	}
`
const LogoWrap = styled.div`
	width: 200px;
	
	a {
		display: block;
		width: 100%;

		.gatsby-image-wrapper {
			width: 100%;
		}
	}
`
const HeaderTopLeft = styled.div`
  position: relative;
	float: right;
	display: flex;
	justify-content: flex-end;
	a {
		margin-right: 30px;
	}
	div {
		margin-right: 30px;
	}
	.btn-search {
		margin-right: 30px;
		& .icon{
			> svg{
				stroke:inherit;
			}
		}
	}
	@media(max-width: 1300px) {
		a {
			margin-right: 10px;
		}
		div {
			margin-right: 10px;
		}
		.btn-search {
			margin-right: 10px;
		}
	}
`

const SearchForm = styled.div`
	position: absolute;
	display: none;
	width: 300px;
	top: 50px;
	left: -50px;
	z-index: 10;
	&.show {
		display: block;
	}
`

const SearchContainer = styled.div`
  position: relative;
  max-width: 300px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 20px;
  @media (min-width: 992px){
    padding: 0px 30px;
  }

	input {
		padding: 10px 15px;
	}
  .input-icon{
    > .form-control{

      &::placeholder {
        color:#004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color:#004485;
        }
      }
    }
    .icon {
      cursor:pointer;
      width:45px;
      > svg{
        fill:#4815D6;
        width:15px;
        height:15px;
      }
      &:hover{
        > svg{
          fill:#4F5864;
        }
      }
    }
    &.right {
      > .form-control{
        padding-right: 45px;
        padding-left: 15px;
      }
    }
  }
`
const HeaderTopRight = styled.div`
	float: right;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	a {
		margin-left: 30px;
	}
	@media(max-width: 1300px) {
		a {
			margin-left: 10px;
		}
	}
`
const MenusWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0 10px;
	@media(max-width: 1200px) {
		display: none;
	}
`
const MenuItem = styled.div`
	position: relative;
	font-size: 16px;
	margin: 0;
	line-height: 1;
	font-weight: 700;
	font-family: "Oswald";
	display: flex;
	align-items: center;
	color: #574C4C;
	text-transform: uppercase;

	svg {
		margin-left: 10px;
		stroke: #574C4C;
	}
	&:hover {
		cursor: pointer;
		color: #E43D3C;
		svg {
			stroke: #E43D3C; 
		}
	}
`

const MenuAlone = styled(Link)`
	position: relative;
	font-size: 16px;
	margin: 0;
	line-height: 1;
	font-weight: 700;
	font-family: "Oswald";
	display: flex;
	align-items: center;
	color: #574C4C;
	text-transform: uppercase;
	&:hover {
		cursor: pointer;
		color: #E43D3C;
	}
`
const Header = ({ location, hideSearch }) => {
	const [currentMenu, setCurrentMenu] = useState("");
	const [searchKey, setSearchKey] = useState(null)
	const [showSearch, setShowSearch] = useState(true) 

  const handleChange = (e) => {
    setSearchKey(e.target.value);
  }

	const ToggleSearch = () => {
    setShowSearch(!showSearch);
  }

	const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/search-result", { state: { searchKey: searchKey } });
  }

	const steelRef = useRef();
	const portableRef = useRef();
	const resourceRef = useRef();

	const handleMenu = (menu) => {
		if (currentMenu === menu ) {
			setCurrentMenu("")
		} else {
			setCurrentMenu(menu)
		}
	}
	const [popupContext, setPopupContext] = useContext(Context);


	const handleOutsideClick = () => setCurrentMenu("");
	useClickOutside([portableRef, steelRef, resourceRef], handleOutsideClick);

	return(
		<HeaderWrap>
			<HeaderInner>
				<Bg> <StaticImage src="../../images/top_mid.png" placeholder="blurred" alt="hero text banner"/></Bg>
				<div className="container">
					<HeaderTop>
						<HeaderTopLeft>
							{!hideSearch && <button  type="button" aria-label="Search" className="btn-search" onClick={ToggleSearch}><PrimaryLinkButton icon={<SearchIcon />} /></button>}
							<a href="tel:3867556449" aria-label="tel"><SecondButton text="" preIcon={<PhoneIcon fill="#fff" />} /></a>
							<div onClick={()=>setPopupContext("block")} aria-hidden="true"><PrimaryLinkButton text="Get a free quote" preIcon={<NoteIcon />} icon={<LineArrowRight />} /></div>
							<SearchForm className={showSearch ? 'search-form' : 'search-form show'}>
								<SearchContainer className='search-container'>
									<form onSubmit={(e)=>handleSubmit(e)}>
										<div className="input-icon right">
											<input  className="form-control" type="text" placeholder="Search..." id="search" name="search" onChange={(e) => handleChange(e)} />
											<button  type="button" aria-label="Close Search" className="icon"  onClick={ToggleSearch}><SearchCloseIcon /></button>
										</div>
									</form>
								</SearchContainer>
							</SearchForm>
						</HeaderTopLeft>
						<LogoWrap><Link to="/"><StaticImage src="../../images/logo.png" placeholder="blurred" alt="Buildings And More"/></Link></LogoWrap>
						<HeaderTopRight>
							<a href="https://buildingsandmore.sensei3d.com/" target="_blank" rel="noreferrer" aria-label="3D Carport Designer"><PrimaryLinkButton text="3D Carport Designer" preIcon={<ThreeDIcon fill="#fff" />} icon={<LineArrowRight />} /></a>
							<a href="https://bambuildingsheds.senseisheds.com/" target="_blank" rel="noreferrer" aria-label="3D Shed Designer"><SecondButton text="3D Shed Designer" preIcon={<ShedIcon fill="#fff" />} icon={<LineArrowRight />} /></a>
						</HeaderTopRight>
					</HeaderTop>
					<MenusWrap>
						<MenuItem onClick={() => handleMenu("Portable Buildings")} ref={portableRef}>
							Portable Buildings  <DropArrow />
							<PortableBuildingsDropdown display={currentMenu==="Portable Buildings"? "block" : "none"} />
						</MenuItem>
						<MenuItem onClick={() => handleMenu("Steel Buildings")} ref={steelRef}>
							Steel Buildings <DropArrow />
							<SteelBuildingsDropdown display={currentMenu==="Steel Buildings"? "block" : "none"} />
						</MenuItem>
						<MenuAlone  to="/red-iron-buildings">Red Iron Buildings</MenuAlone>
						<MenuAlone  to="/pole-barns">Pole Barns</MenuAlone>
						<MenuAlone  to="/gazebos">Gazebos</MenuAlone>
						<MenuAlone  to="/greenhouses">Greenhouses</MenuAlone>
						<MenuAlone  to="/screen-rooms">Screen Rooms</MenuAlone>
						<MenuItem onClick={() => handleMenu("Resources")} ref={resourceRef}>
							Resources <DropArrow />
							<ResourcesDropdown display={currentMenu==="Resources"? "block" : "none"} />
						</MenuItem>
					</MenusWrap>
					<MobileNav hideSearch={hideSearch} />
				</div>
			</HeaderInner>
		</HeaderWrap>
	)
}

export default Header;